import { createStore } from "vuex"
import { getAll as getAllUsers } from '@/api/providers/users';

const store = createStore({
	state: {
		users: null,

		loadUsersPromise: null,
	},

	getters: {
		users: (state) => state.users ?? [],
		dropdownUsers: (state) => (state.users ?? []).map(u => {
			return {
				value: u.id,
				label: `${u.firstName ?? ''} ${u.insertion ?? ''} ${u.lastName ?? ''}`,
				image: u.profilePicture
			}
		}),
	},

	mutations: {
		updateUsers: (state, payload) => state.users = payload,
		updateLoadUsersPromise: (state, payload) => state.loadUsersPromise = payload,
	},

	actions: {
		loadUserList({ commit }) {
			if (this.state.loadUsersPromise != null) {
				return this.state.loadUsersPromise;
			}

			if (this.state.users != null) return new Promise(
				(resolve) => resolve(this.state.users)
			);

			let promise = new Promise((resolve) => {
				if (this.state.users != null) resolve(this.state.users);

				getAllUsers().then(users => {
					commit('updateUsers', users);
					commit('updateLoadUsersPromise', null);
					resolve(users)
				});
			});

			commit(
				'updateLoadUsersPromise',
				promise
			);

			return promise;
		}
	},

	methods: {
		async loadUsers() {
			try {
				return await getAllUsers();
			}
			catch {
				return [];
			}
		}
	}
});

export default store;