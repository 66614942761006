<template>
  <div class="card-body pt-0">
    <h6>Deelnemers</h6>
    <div>
      <p
        class="mb-0"
      >
        Aanwezig ({{ goingUsers.length }} + {{ guestCount }} gasten)
      </p>
      <user-avatar-list
        :users="goingUsers"
        show-guest-count
      />
    </div>

    <dropdown
      :display-last-selected-option="false"
      :options="dropdownUsers"
      border-color="#e91e6380"
      placeholder-label="Gebruiker toevoegen"
      @option-selected="onDropdownOptionSelected"
    />

    <div>
      <p
        class="mb-0"
      >
        Misschien ({{ maybeUsers.length }})
      </p>
      <user-avatar-list
        :users="maybeUsers"
      />
    </div>

    <hr />

    <div>
      <p
        class="mb-0"
      >
        Afwezig ({{ notGoingUsers.length }})
      </p>
      <user-avatar-list
        :users="notGoingUsers"
      />
    </div>
    <button
      class="btn btn-primary w-100 mt-2"
      @click="downloadGuestList"
    >
    
      <material-icon icon="download" />
      Exporteer gastenlijst
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Dropdown from '@/components/UI/Dropdown.vue';
import MaterialIcon from '@/components/UI/MaterialIcon.vue';
import UserAvatarList from '@/components/UI/UserAvatarList.vue';

import {
  getGuestListCsv,
  updateUserEventAttendance,
  UserAttendanceStatus,
} from '@/api/providers/events';

import editEventStore from '@/store/pages/edit-event';

export default {
  components: {
    Dropdown,
    MaterialIcon,
    UserAvatarList
  },
  props: {
    eventId: {
      type: String,
      required: true
    },
    eventTitle: {
      type: String,
      required: true,
    },
    eventStartDate: {
      type: String,
      required: true
    },
    users: {
      type: Array,
      default: () => []
    },
  },

  emits: [
    'user-added'
  ],

  computed: {
    goingUsers() { 
      return this.users.filter(u => u.isGoing == 0);
    },
    maybeUsers() { 
      return this.users.filter(u => u.isGoing == 1);
    },
    notGoingUsers() { 
      return this.users.filter(u => u.isGoing == 2);
    },
    guestCount() {
      let count = 0;
      for(let user of this.goingUsers) {
        count += user.guestNameList.length
      }
      
      return count;
    },
    dropdownUsers() {
      return editEventStore.getters.dropdownUsers.filter(
        du => !this.goingUsers.find(gu => gu.id === du.value)
      );
    }
  },

  methods: {
    ...mapActions(
      'notification',
      {
        addNotification: 'add',
        addError: 'addError'
      }
    ),

    async downloadGuestList() {
      const csv = await getGuestListCsv(this.eventId);

      const blob = new Blob([csv], { type: 'text/csv' });

      const url = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `Gastenlijst '${this.eventTitle}' ${this.eventStartDate.substring(0, 10)}`;

      document.body.appendChild(anchor);
      anchor.click();

      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(url);
    },

    async onDropdownOptionSelected(option) {
      let user = editEventStore.getters.users
        .find(u => u.id == option.value);

      if (user == null) return;

      try {
        await updateUserEventAttendance(
          user.id,
          this.eventId,
          UserAttendanceStatus.going,
          false,
          []
        );

        this.$emit(
          'user-added',
          {
            guestNameList: [],
            id: user.id,
            isGoing: UserAttendanceStatus.going,
            takesGuest: false,
            userImage: user.profilePicture,
            userName: `${user.firstName} ${user.lastName}`
          }
        );

        this.addNotification({
          description: `Gebruiker is toegevoegd`,
          icon: {
            component: 'person',
          },
        });
      }
      catch {
        this.addError('Kon gebruiker niet toevoegen');
      }
    }
  }
}
</script>