<template>
  <div 
    class="row mb-2"
  >
    <div
      class="col-4"
    >
      <dropdown
        label="Gebruiker toevoegen"
        :options="options" @update:value="addUser"
      />
    </div>
  </div>
  <div
    class="row"
  >
    <div 
      v-for="user in filteredUsers" 
      :key="user.id" 
      class="col-4 col-sm-6 my-3"
    >
      <div
        class="card user-card"
      >
        <div 
          class="p-3 card-body"
        >
          <div
            class="d-flex mt-n2"
          >
            <div
              class="mt-n4 overflow-hidden flex-shrink-0"
            >
              <profile-picture 
                :width-and-height-in-pixels="50" 
                :background-image-url="user.image"
              />
            </div>
            <div
              class="my-auto ms-3"
            >
              <h6
                class="mb-0"
              >
                {{ user.name }}
              </h6>
            </div>
            <div 
              class="ms-auto"
            >
            <dropdown-menu
              button-padding-class="ps-4"
              :items="createDropdownOptions(user)"
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/UI/Dropdown.vue';
import ProfilePicture from '@/components/UI/ProfilePicture.vue';
import DropdownMenu from '@/components/UI/DropdownMenu.vue';
import { createYesNoSweetAlert } from '@/helpers/sweetalert'
import { 
  addUsersToRestrictedEvent, 
  removeUserFromRestrictedEvent
} from '../../../api/providers/events';

import { toRaw } from 'vue';

import editEventStore from '@/store/pages/edit-event';

export default {
  components: {
    Dropdown,
    ProfilePicture,
    DropdownMenu,
  },

  props: {
    userIds: { type: Array, required: true },
    showUpdate: { type: Boolean, default: true },
  },

  data() {
    return {
      selectedUsers: []
    };
  },

  computed: {
    filteredUsers() { return this.selectedUsers.filter(u => !u.isDeleted); },
    options() {
      return editEventStore.getters.dropdownUsers;
    },
  },

  async mounted() {
    await editEventStore.dispatch('loadUserList')
    this.initializeSelectedUsers(); 
  },

  methods: {
    initializeSelectedUsers() {
      const options = this.options.filter(o => 
        (this.userIds ?? []).includes(Number(o.value))
      ).map(o => toRaw(o));

      for (const option of options){
        this.selectedUsers.push({
          id: option.value,
          name: option.label,
          image: option.image
        });
      }
    },

    addUser(id) {
      if (this.selectedUsers.find(u => u.id === id) != null) return;

      let option = this.options.find(o => o.value === id);
      if (option == null) return;

      this.selectedUsers.push({
        id: option.value,
        name: option.label,
        image: option.image,
        isNew: true
      });
    },

    createDropdownOptions(user) {
      return [
        {
          handler: () => this.removeUserFromGroup(user),
          name: `${user.name} verwijderen`
        },
      ];
    },

    removeUserFromGroup(user) {
      createYesNoSweetAlert(
        `Weet u zeker dat u ${user.name} uit de groep wilt verwijderen?`,
        () => {
          const userIndex = this.selectedUsers.findIndex(u => u === user);
          if (userIndex < 0) return;

          this.selectedUsers[userIndex] = { ...user, isDeleted: true };
        }
      );
    },

    async submit(eventId) {
      await this.submitAddedUsers(eventId);
      await this.submitRemovedUsers(eventId);
    },

    async submitAddedUsers(eventId) {
      await addUsersToRestrictedEvent(
        this.selectedUsers
          .filter(u => u.isNew && !u.isDeleted)
          .map(u => u.id),
          eventId
      );
    },

    async submitRemovedUsers(eventId) {
      const deletedUserIds = this.selectedUsers
          .filter(u => u.isDeleted && !u.isNew)
          .map(u => u.id);

      for (const userId of deletedUserIds) {
        removeUserFromRestrictedEvent(userId, eventId)
      }
    }
  }
}
</script>